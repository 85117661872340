import {ApiService} from '../shared/services/api.service';
import {Injectable} from '@angular/core';

@Injectable()
export class ZordersApiService extends ApiService {

  /**
   * Получение списка складов
   * @param params Фильтр отбора
   */
  public getWarehouses(params) {
    return this.get('/client/zorders/warehouses?' + this.getQueryStringFromArray(params));
  }

  /**
   * Получение записи склада
   * @param idOrCode id или client_code
   */
  public getWarehouse(idOrCode: string | number) {
    return this.get('/client/zorders/warehouses/' + idOrCode);
  }

  /**
   * Создание склада
   * @param payload Информация о складе
   */
  public createWarehouse(payload) {
    return this.post('/client/zorders/warehouses', payload);
  }

  /**
   * Проверка адреса склада
   * @param payload Информация о складе
   */
  public checkWarehouseAddress(payload) {
    return this.post('/client/zorders/warehouses/check-address', payload);
  }

  /**
   * Обновление склада
   * @param idOrCode id или client_code
   * @param payload Информация о складе
   */
  public updateWarehouse(idOrCode, payload) {
    return this.post('/client/zorders/warehouses/' + idOrCode, payload);
  }

  /**
   * Массовая устанавка видимости складов в ЛК
   * @param payload Список складов и желаемое состояние видимости
   */
  public setWarehousesVisibility(payload) {
    return this.post('/client/zorders/warehouses/set-visibility', payload);
  }

  /**
   * Проверка доступности забора "День в день" для склада
   * @param payload Склад для проверки
   */
  public checkSamedayAvailableForWarehouse(payload) {
    return this.post('/client/zorders/warehouses/check-sameday-available', payload);
  }

  /**
   * Получение списка заборов
   * @param params Фильтр отбора
   */
  public getZorders(params) {
    return this.get('/client/zorders?' + this.getQueryStringFromArray(params));
  }

  /**
   * Статусы заборов
   */
  public getZorderStatuses() {
    return this.get('/client/zorders/statuses');
  }

  /**
   * Получение записи забора
   * @param idOrCode id или inner_n
   */
  public getZorder(idOrInnerN) {
    return this.get('/client/zorders/' + idOrInnerN);
  }

  /**
   * Создание забора
   * @param payload Информация о заборе
   */
  public createZorder(payload) {
    return this.post('/client/zorders', payload);
  }

  /**
   * Обновление забора
   * @param idOrCode id или client_code
   * @param payload Информация о заборе
   */
  public updateZorder(idOrInnerN, payload) {
    return this.post('/client/zorders/' + idOrInnerN, payload);
  }

  /**
   * Отмена заборов
   * @param data
   */
  public cancelZorders(data) {
    return this.post('/client/zorders/cancelZorders', data);
  }

  /**
   * Массовое обновление складов
   * @param params
   */
  public bulkWarehousesUpdate(params) {
    return this.post('/client/zorders/warehouses/bulkUpdate', params);
  }

}
