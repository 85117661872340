import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class LogsisErrorHandlerService {
  private http: HttpClient;

  constructor(
    private handler: HttpBackend
  ) {
    this.http = new HttpClient(handler);
  }

  public sendError(error: Error, path: string) {
    this.http.post(environment.logsisBackendUrl + '/client/handleError', {
      message: error.message,
      stack: error.stack,
      name: error.name,
      url_path: path,
    })
    .subscribe((response) => {
    });
  }
}
