import {Component, OnDestroy, OnInit} from '@angular/core';
import * as fromApp from './store/app.reducer';
import * as UserReducer from './store/user/user.reducer';
import * as AuthActions from './auth/store/auth.actions';
import {Store} from '@ngrx/store';
import {EventerService} from './shared/services/eventer.service';
import {fromEvent, Observable, Subscription} from 'rxjs';
import {WindowHelper} from './shared/helpers/window.helper';
import {distinctUntilChanged, throttleTime} from 'rxjs/operators';
import {async} from 'rxjs/internal/scheduler/async';
import {UserModel} from './shared/models/user.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public user: UserModel;
  public userState: UserReducer.State;
  public sidebarIsMinified: boolean = false;
  public isMobileWidth: boolean = WindowHelper.isMobileWidth();

  public noTemplate: boolean = false;

  private resizeObservable: Observable<Event>;
  private resizeSubscription: Subscription;

  constructor(
    private store: Store<fromApp.AppState>,
    private eventerService: EventerService
  ) {
  }

  ngOnInit() {
    this.store.dispatch(new AuthActions.AutoLogin());

    this.store.select('auth')
      .subscribe((authState) => {
          this.user = authState.user;
        }
      )

    this.store.select('user')
      .subscribe((userState) => {
        this.userState = userState;
        if (userState.sidebarIsMinified && WindowHelper.isMobileWidth()) {
          this.sidebarIsMinified = false;
        } else {
          this.sidebarIsMinified = userState.sidebarIsMinified;
        }
      })

    this.resizeObservable = fromEvent(window, 'resize')
    this.resizeSubscription = this.resizeObservable
      .pipe(
        distinctUntilChanged(),
        throttleTime(500, async, {leading: false, trailing: true})
      )
      .subscribe( evt => {
        this.isMobileWidth = WindowHelper.isMobileWidth();
        this.eventerService.windowResize.emit();
      })
  }

  ngOnDestroy() {
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
    }
  }
}
