import {BaseModel} from './base.model';

export class PermissionModel extends BaseModel {
  public key: string;
  public description: string;

  public static readonly ORDERS_VIEW = 'orders:view';
  public static readonly ORDERS_CREATE = 'orders:create';
  public static readonly ORDERS_DELETE = 'orders:delete';
  public static readonly ACTS_VIEW = 'acts:view';
  public static readonly ACTS_CREATE = 'acts:create';
  public static readonly REPORTS_VIEW = 'reports:view';
  public static readonly REPORTS_UPDATE = 'reports:update';
  public static readonly WAREHOUSES_VIEW = 'warehouses:view';
  public static readonly WAREHOUSES_CREATE = 'warehouses:create';
  public static readonly ZORDERS_VIEW = 'zorders:view';
  public static readonly ZORDERS_CREATE = 'zorders:create';
  public static readonly RETURN_ACTS_VIEW = 'return-acts:view';
  public static readonly PROFILE_EDIT = 'profile:edit';
  public static readonly USERS_VIEW = 'users:view';
  public static readonly USERS_CREATE = 'users:create';
}
