import {BaseModel} from './base.model';

export class ClientUserSettingsKeysModel extends BaseModel {

  /** @var string Инструкция о создании заказа показана */
  public static IS_INSTRUCTION_CREATE_ORDER_VIEWED = 'is_instruction_create_order_viewed';

  public key: string;
  public name: string;
  public is_editable: number;
  public type: string;
  public created_at: Date;
  public updated_at: Date;
}
