import {ErrorHandler, Injectable} from '@angular/core';
import {LogsisErrorHandlerService} from './logsis-error-handler.service';

@Injectable()
export class LogsisErrorHandler implements ErrorHandler {
  constructor(private logsisErrorHandlerService: LogsisErrorHandlerService) {
  }

  handleError(error: any) {
    console.error(error);

    this.logsisErrorHandlerService.sendError(error, document.location.pathname);
  }
}
