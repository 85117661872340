import {ClientModel} from '../models/client.model';
import {UserModel} from '../models/user.model';
import {HubRestrictModel} from '../models/hub-restrict.model';

export class ClientHelper {
  /**
   * Получить значение настройки по ключу
   * @param client
   * @param settingKey
   */
  public static getClientSettingValue(client: ClientModel | UserModel, settingKey: string) {
    const settings = client.settings;
    if (!settings || settings.length === 0) {
      return null;
    }

    const filteredSetting = settings.find(setting => {
      return (setting.setting_key.key === settingKey);
    })

    return filteredSetting ? filteredSetting.setting_value : null;
  }

  public static getClientRestriction(restrictions: HubRestrictModel[], hubId: number, deliveryTypeId: number): HubRestrictModel {
    if (!restrictions ||  restrictions.length === 0) {
      return null;
    }

    const restrictionsByHub = restrictions.filter(restrict => {
      return (restrict.hub_destination_id === hubId || restrict.hub_destination_id === null);
    });

    const restrictionByDeliveryType = restrictionsByHub.find(restrict => {
      return (restrict.delivery_type_id === deliveryTypeId  || restrict.delivery_type_id === null);
    });

    return restrictionByDeliveryType ? restrictionByDeliveryType : null;
  }
}
