import {BaseModel} from './base.model';
import {HubDeliveryIntervalModel} from './hub-delivery-interval.model';

export class HubModel extends BaseModel {
  // Хабы Логсис
  public static readonly TYPE_LOGSIS = 1;
  // Хабы ТопДеливери
  public static readonly TYPE_TOPDELIVERY = 2;

  /** Хабы партнеров */
  public static readonly TYPE_PARTNER = 3;

  /** Хабы маркетплейсов */
  public static readonly TYPE_MARKETPLACE = 4;

  // ID хаба Москвы
  public static readonly HUB_MSK = 1;

  // ID хаба Спб
  public static readonly HUB_SPB = 2;

  // ID хаба Топделивери
  public static readonly HUB_TOPDELIVERY = 3;

  // Хаб КГТ = 4ж
  public static readonly HUB_KGT = 4;

  // Хаб Омник
  public static readonly HUB_OMNIC = 5;

  // Хаб Вайлдбериз
  public static readonly HUB_WB = 6;

  // Хаб озон
  public static readonly HUB_OZN = 7;

  /** @var int Хаб Омник СПб */
  public static readonly HUB_OMNIC_SPB = 8;

  /** @var int Хаб Халва */
  public static readonly HUB_HALVA = 9;

  /** @var int Хаб Боксберри */
  public static readonly HUB_BOXBERRY = 10;

  /** @var int Хаб 5Пост */
  public static readonly HUB_FIVEPOST = 11;

  /** @var int Хаб Халва Питер */
  public static readonly HUB_HALVA_SPB = 12;

  /** @var int Хаб Exmail */
  public static readonly HUB_EXMAIL = 13;

  /**
   * Хабы самовывоза
   */
  public static readonly HUBS_SELF_DELIVERY = [
    HubModel.HUB_OMNIC,
    HubModel.HUB_OMNIC_SPB,
    HubModel.HUB_HALVA,
    HubModel.HUB_BOXBERRY,
    HubModel.HUB_FIVEPOST,
    HubModel.HUB_HALVA_SPB,
    HubModel.HUB_EXMAIL,
  ];

  public name: string;
  public address: string;
  public postal_code: string;
  public full_name: string;
  public full_name_id: string;
  public map_zoom: number;
  public map_center_lat: number;
  public map_center_lon: number;
  public delivery_intervals?: HubDeliveryIntervalModel[];
  public default_delivery_interval?: HubDeliveryIntervalModel;
  public type: number;
  public default_interval_id?: number;

  constructor(data: any) {
    super(data);

    this.full_name_id = this.name + ' [' + this.id + ']';

    if (data.hasOwnProperty('delivery_intervals') && data.delivery_intervals.length) {
      this.delivery_intervals = data.delivery_intervals.map(interval => {
        return new HubDeliveryIntervalModel(interval);
      });

      this.default_delivery_interval = this.delivery_intervals.find((interval: HubDeliveryIntervalModel) => {
        return interval.id === this.default_interval_id;
      });
    }
  }
}
