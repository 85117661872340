import * as AuthReducer from '../auth/store/auth.reducer';
import * as UserReducer from '../store/user/user.reducer';

import {ActionReducerMap} from '@ngrx/store';

export interface AppState {
  auth: AuthReducer.State;
  user: UserReducer.State;
}

export const appReducer: ActionReducerMap<AppState> = {
  auth: AuthReducer.authReducer,
  user: UserReducer.userReducer
};
