<mat-autocomplete
  #auto="matAutocomplete"
  (optionSelected)="onAddressSelect($event)"
  [displayWith]="displayFn"
>
  <mat-option
    class="mat-option__dadata-address mat-option"
    *ngFor="let option of suggestions | async"
    [value]="option"
  >
    <b>{{option.brand_name}}: {{option.name}}</b>
    <br>
    {{option.address}}
    <br>
    <span class="text-secondary" *ngIf="option.delivery_period">
      Срок доставки: {{option.delivery_period}}
    </span>
  </mat-option>
</mat-autocomplete>

<div [formGroup]="parentFormGroup">
  <textarea
    autocomplete="no-autocomplete"
    [id]="textareaId"
    [formControlName]="textareaControlName"
    placeholder="Начните вводить адрес либо код пункта выдачи"
    class="form-control form-control-sm {{textareaClass}}"
    [matAutocomplete]="auto"
    [disabled]="disabled"
    (input)="onAddressInput($event)"
  ></textarea>
</div>
