import { Component, OnInit } from '@angular/core';
import {Store} from '@ngrx/store';
import * as AppReducer from '../../store/app.reducer';
import {Router} from '@angular/router';

@Component({
  selector: 'app-home-page-redirect',
  template: '',
})
export class HomePageRedirectComponent implements OnInit {

  constructor(
    private store: Store<AppReducer.AppState>,
    private router: Router,
  ) {
    this.store.select('auth')
      .subscribe((userState) => {
          const user = userState.user;

          if (user) {
            this.router.navigate([user.getDefaultUrl()]);
          } else {
            this.router.navigate(['/orders/list']);
          }
        }
      )
  }

  ngOnInit(): void {
  }

}
