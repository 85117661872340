import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { switchMap, catchError, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import * as AuthActions from './auth.actions';
import { UserModel } from '../../shared/models/user.model';
import { AuthService } from '../auth.service';
import {ClientModel} from '../../shared/models/client.model';
import {AccessTokenModel} from '../../shared/models/access-token.model';
import {ApiResult} from '../../shared/services/api.service';
import {ClientHelper} from '../../shared/helpers/client.helper';
import {ClientUserSettingsKeysModel} from '../../shared/models/client-user-settings-keys.model';

export interface AuthResponseData {
  access_token: string;
  expires_in: number;
  client: ClientModel;
  user: UserModel;
}

const handleAuthentication = (
  resData: AuthResponseData,
  redirect: boolean
) => {
  const expirationDate = new Date(new Date().getTime() + resData.expires_in * 1000);

  const accessToken = new AccessTokenModel(resData.access_token, expirationDate);

  localStorage.setItem('userData', JSON.stringify(resData.user));
  localStorage.setItem('clientData', JSON.stringify(resData.client));
  localStorage.setItem('accessToken', JSON.stringify(accessToken));

  return new AuthActions.AuthenticateSuccess({
    user: new UserModel(resData.user),
    client: resData.client,
    token: accessToken,
    redirect
  });
};

const handleError = (errorRes: any) => {
  return of(new AuthActions.AuthenticateFail(errorRes.error.response.error));
};

@Injectable()
export class AuthEffects {
  @Effect()
  authLogin = this.actions$.pipe(
    ofType(AuthActions.LOGIN_START),
    switchMap((authData: AuthActions.LoginStart) => {
      return this.http
        .post<ApiResult>(
          environment.logsisBackendUrl + '/client/login',
          {
            login: authData.payload.login,
            password: authData.payload.password
          }
        )
        .pipe(
          tap(resData => {
            this.authService.setLogoutTimer(resData.response.expires_in * 1000);
          }),
          map(resData => {
            return handleAuthentication(resData.response, true);
          }),
          catchError(errorRes => {
            return handleError(errorRes);
          })
        );
    })
  );

  @Effect()
  refreshToken = this.actions$.pipe(
    ofType(AuthActions.REFRESH_TOKEN),
    switchMap((authData: AuthActions.RefreshToken) => {
      return this.http
        .post<ApiResult>(
          environment.logsisBackendUrl + '/client/refreshToken',
          {}
        )
        .pipe(
          tap(resData => {
            this.authService.setLogoutTimer(resData.response.expires_in * 1000);
          }),
          map(resData => {
            return handleAuthentication(resData.response, false);
          }),
          catchError(errorRes => {
            return handleError(errorRes);
          })
        );
    })
  );

  @Effect({ dispatch: false })
  authRedirect = this.actions$.pipe(
    ofType(AuthActions.AUTHENTICATE_SUCCESS),
    tap((authSuccessAction: AuthActions.AuthenticateSuccess) => {
      if (authSuccessAction.payload.redirect) {
        const user: UserModel = new UserModel(JSON.parse(localStorage.getItem('userData')));
        if (!ClientHelper.getClientSettingValue(user, ClientUserSettingsKeysModel.IS_INSTRUCTION_CREATE_ORDER_VIEWED)) {
          this.http.post<ApiResult>(environment.logsisBackendUrl + '/client/mark-instruction-create-order-viewed', {}).subscribe(data => {
            setTimeout(() => {
              this.router.navigate(['/instructions/lk-principal']);
            }, 0);
          });
        } else {
          setTimeout(() => {
            this.router.navigate(['/']);
          }, 0);
        }
      }
    })
  );

  @Effect()
  autoLogin = this.actions$.pipe(
    ofType(AuthActions.AUTO_LOGIN),
    map(() => {
      const user: UserModel = new UserModel(JSON.parse(localStorage.getItem('userData')));
      const client: ClientModel = JSON.parse(localStorage.getItem('clientData'));
      const tokenData = JSON.parse(localStorage.getItem('accessToken'));

      if (tokenData) {
        const token: AccessTokenModel = new AccessTokenModel(tokenData._token, tokenData._tokenExpirationDate);

        if (token.token) {
          this.authService.setLogoutTimer(token.expirationInSeconds * 1000);

          return new AuthActions.AuthenticateSuccess({
            user,
            client,
            token,
            redirect: false
          });
        }
      }

      return { type: 'No active token' };
    })
  );

  @Effect({ dispatch: false })
  authLogout = this.actions$.pipe(
    ofType(AuthActions.LOGOUT),
    tap(() => {
      this.authService.clearLogoutTimer();

      localStorage.removeItem('userData');
      localStorage.removeItem('clientData');
      localStorage.removeItem('accessToken');

      this.router.navigate(['/auth']);
    })
  );

  @Effect({ dispatch: false })
  updateClientData = this.actions$.pipe(
    ofType(AuthActions.UPDATE_CLIENT_DATA),
    tap((clientData: AuthActions.UpdateClientData) => {
      localStorage.setItem('clientData', JSON.stringify(clientData.payload));
    })
  );

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private router: Router,
    private authService: AuthService,
  ) {}
}
