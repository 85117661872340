import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {Store} from '@ngrx/store';

import {AuthService} from '../../auth/auth.service';
import * as AuthActions from '../../auth/store/auth.actions';
import * as UserActions from '../../store/user/user.actions';
import * as AppReducer from '../../store/app.reducer';
import * as UserReducer from '../../store/user/user.reducer';
import {UserModel} from '../../shared/models/user.model';
import {ClientModel} from '../../shared/models/client.model';
import {EventerService} from '../../shared/services/eventer.service';
import {NavigationEnd, Router} from '@angular/router';
import {WindowHelper} from '../../shared/helpers/window.helper';
import {ApiService} from '../../shared/services/api.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})

export class HeaderComponent implements OnInit, OnDestroy {

  public user: UserModel;
  public userState: UserReducer.State;
  public client: ClientModel;
  public manager;
  public managerEmail;
  public exclusionManagerId = 23; // Анастасия Михайлова это временный менеджер, который не отображается для клиентов

  public isSidebarOpened: boolean = null;
  public isProfilePopupOpened: boolean = false;
  @ViewChild('profilePopup') profilePopup: ElementRef;

  private routerSubscription: Subscription;

  @HostListener('document:click', ['$event']) toggleOpen(event: Event) {
    if (!this.profilePopup) {
      return;
    }

    if (!this.profilePopup.nativeElement.contains(event.target) && this.isProfilePopupOpened) {
      this.isProfilePopupOpened = false;
    }
  }

  constructor(private authService: AuthService,
              private eventerService: EventerService,
              private api: ApiService,
              private router: Router,
              private store: Store<AppReducer.AppState>) {
  }

  isAuthenticated: boolean = false;
  private userSubscription: Subscription;

  ngOnInit(): void {
    this.store.select('user')
      .subscribe((userState) => {
        this.userState = userState;

        if (this.isSidebarOpened === null) {
          this.isSidebarOpened = !userState.sidebarIsMinified;
        }
      })

    this.store.select('auth')
      .subscribe((userState) => {
          this.user = userState.user;
          this.client = userState.client;

          this.isAuthenticated = !!userState.user;
        }
      )

    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && WindowHelper.isMobileWidth()) {
        this.isSidebarOpened = false;
        this.eventerService.adminSidebarToggle.emit(false);
      }
    })

    this.eventerService.windowResize.subscribe(() => {
      if (!this.userState.sidebarIsMinified && !WindowHelper.isMobileWidth()) {
        this.isSidebarOpened = true;
      } else if (WindowHelper.isMobileWidth()) {
        this.isSidebarOpened = false;
      }
    })

    this.setDataManager();
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

  getUserEmail(userId) {
    this.api.getUserEmail(userId).subscribe(data => {
      console.log(data);
      this.managerEmail = data.email;
    });
  }

  setDataManager() {
    if (this.client.account_manager_id === null || this.client.account_manager_id === this.exclusionManagerId) {
        this.getUserEmail(this.client.sales_manager_id);
        this.manager = this.client.sales_manager_name;
    } else {
      if (this.client.account_manager_id) {
        this.getUserEmail(this.client.account_manager_id);
        this.manager = this.client.account_manager_name;
      }
    }
  }

  onLogoutClick() {
    this.isProfilePopupOpened = false;
    this.store.dispatch(new AuthActions.Logout());
  }

  onShowMenuClick() {
    this.isSidebarOpened = !this.isSidebarOpened;
    if (!WindowHelper.isMobileWidth()) {
      // для больших экранов сохраним состояние
      this.store.dispatch(new UserActions.SetSidebarMinified(!this.isSidebarOpened));
    }

    this.eventerService.adminSidebarToggle.emit(this.isSidebarOpened);
  }
}
