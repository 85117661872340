import {BaseModel} from './base.model';
import {ClientUserSettingsModel} from './client-user-settings.model';
import {PermissionModel} from './permission.model';

export class UserModel extends BaseModel {
  public id: number;
  public client_id: number;
  public email: string;
  public login: string;
  public phone: string;
  public position: string;
  public password: string;
  public is_old_password: number;
  public is_admin: number;
  public last_login: Date;
  public created_at: Date;
  public updated_at: Date;
  public deleted_at: Date;

  public settings: ClientUserSettingsModel[];
  public permissions: PermissionModel[];

  public hasPermission(ability: string) {
    if (this.is_admin) {
      return true;
    }

    for (let permission of this.permissions) {
      if (permission.key === ability) {
        return true;
      }
    }

    return false;
  }

  // возвращает дефолтный путь
  public getDefaultUrl() {
    if (this.hasPermission(PermissionModel.ORDERS_VIEW)) {
      return '/orders/list';
    }

    if (this.hasPermission(PermissionModel.ZORDERS_VIEW)) {
      return '/zorders';
    }

    if (this.hasPermission(PermissionModel.ACTS_VIEW)) {
      return '/acts/list';
    }

    if (this.hasPermission(PermissionModel.REPORTS_VIEW)) {
      return '/reports/money';
    }

    if (this.hasPermission(PermissionModel.RETURN_ACTS_VIEW)) {
      return '/returns';
    }

    // если вообще никаких прав нет, покажем инструкции
    return '/instructions';
  }
}
