import {Directive, ElementRef, HostBinding, HostListener} from '@angular/core';

@Directive({
  selector: '[appDropdown]'
})

export class DropdownDirective {
  @HostBinding('class.app-dropdown-open') opened: boolean = false;

  constructor(private elementRef: ElementRef) {
  }

  @HostListener('document:click', ['$event']) toggleOpen(event: Event) {
    this.opened = this.elementRef.nativeElement.contains(event.target)
      ? !this.opened
      : false;
  }
}
